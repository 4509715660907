<template>
  <div class="manage-stats" :class="{skeleton: !state.loaded}">
    <div class="row">
      <div class="col-lg-3">
        <div class="form-group">
          <label class="subject font-sm">프로젝트 기간</label>
          <input type="text" class="form-control font-sm"
                 :value="`${$route.params.projectType === 'invest' ? project.fundingStartDate : project.investStartDate} ~ ${($route.params.projectType === 'invest' ? project.fundingEndDate : project.investEndDate).substr(0, 10)}`"
                 disabled/>
        </div>
        <div class="form-group">
          <label :for="`${component.name}Year`" class="subject font-sm">조회 연도</label>
          <select :id="`${component.name}Year`" class="form-control border-focus-point font-sm" @change="onChange()" v-model="state.args.year">
            <option v-for="(y, idx) in state.years" :value="y" :key="idx">{{ y }}년</option>
          </select>
        </div>
        <div class="form-group mb-0">
          <label :for="`${component.name}Month`" class="subject font-sm">조회 월</label>
          <select :id="`${component.name}Month`" class="form-control border-focus-point font-sm" @change="load()" v-model="state.args.month">
            <option :value="m" v-for="(m, idx) in state.months" :key="idx">{{ Number(m) }}월</option>
          </select>
        </div>
      </div>
      <div class="col-lg-3 mt-5 mt-lg-0">
        <div class="subject font-sm clearfix pb-2">
          <span>일별 {{ $route.params.projectType === "reward" ? "후원자" : "투자자" }} 수</span>
          <span class="font-xs float-right">(누적 {{ $lib.getNumberFormat($route.params.projectType === "invest" ? project.subscribeCount : project.investorCnt) }}명)</span>
        </div>
        <table class="table">
          <thead class="thead-light">
          <tr>
            <th>
              <span>일자</span>
            </th>
            <th class="text-right">
              <span>{{ $route.params.projectType === "reward" ? "후원자" : "투자자" }}</span>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(d, idx) in state.stats.investors" :key="idx">
            <td>
              <span>{{ d.date }}</span>
            </td>
            <td class="text-right">
              <span>{{ $lib.getNumberFormat(d.value) }}</span>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="col-lg-3 mt-5 mt-lg-0">
        <div class="subject font-sm clearfix pb-2">
          <span>일별 {{ $route.params.projectType === "reward" ? "후원" : "투자" }} 금액</span>
          <span class="font-xs float-right">(누적 {{ $lib.getNumberFormat($route.params.projectType === "invest" ? project.subscribeAmount : project.expenseAmt) }}원)</span>
        </div>
        <table class="table">
          <thead class="thead-light">
          <tr>
            <th>
              <span>일자</span>
            </th>
            <th class="text-right">
              <span>{{ $route.params.projectType === "reward" ? "후원" : "투자" }} 금액</span>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(d, idx) in state.stats.amounts" :key="idx">
            <td>
              <span>{{ d.date }}</span>
            </td>
            <td class="text-right">
              <span>{{ $lib.getNumberFormat(d.value) }}</span>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="col-lg-3 mt-5 mt-lg-0">
        <div class="subject font-sm clearfix pb-2">
          <span>일별 방문자 수</span>
          <span class="font-xs float-right">(누적 {{ $lib.getNumberFormat(state.viewTotCnt) }}명)</span>
        </div>
        <table class="table">
          <thead class="thead-light">
          <tr>
            <th>
              <span>일자</span>
            </th>
            <th class="text-right">
              <span>방문자</span>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(d, idx) in state.stats.views" :key="idx">
            <td>
              <span>{{ d.date }}</span>
            </td>
            <td class="text-right">
              <span>{{ $lib.getNumberFormat(d.value) }}</span>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import http from "@/scripts/http";
import router from "@/scripts/router";
import {httpError} from "@/scripts/httpError";
import lib from "@/scripts/lib";

function Component(initialize) {
  this.name = "pageManageStats";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  props: {
    project: Object
  },
  setup(props) {
    const component = new Component(() => {
      setYears();
      state.args.year = state.years[state.years.length - 1];

      setMonths();
      state.args.month = state.months[state.months.length - 1];

      for (let i = 0; i < 28; i += 1) {
        for (let j in state.stats) {
          state.stats[j].push({
            date: "0000-00-00",
            count: "0"
          });
        }
      }

      load();
    });

    const state = reactive({
      loaded: false,
      years: [],
      months: [],
      args: {
        year: "",
        month: "",
      },
      stats: {
        investors: [],
        amounts: [],
        views: [],
      },
      viewTotCnt: 0,
    });

    const projectType = router.app.$route.params.projectType;
    const projectSeq = router.app.$route.params.projectSeq;

    const load = async () => {
      state.loaded = false;

      const promises = [
        http.get(`/api/maker/${projectType}/${projectSeq}/stats/daily-investor`, state.args).catch(httpError()),
        http.get(`/api/maker/${projectType}/${projectSeq}/stats/daily-amount`, state.args).catch(httpError()),
        http.get(`/api/maker/${projectType}/${projectSeq}/stats/daily-view`, state.args).catch(httpError()),
        http.get(`/api/maker/${projectType}/${projectSeq}/stats/total-view`, state.args).catch(httpError()),
      ];

      Promise.all(promises).then((responses) => {
        state.loaded = true;
        state.stats.investors = responses[0].data.body;
        state.stats.amounts = responses[1].data.body;
        state.stats.views = responses[2].data.body;
        state.viewTotCnt = responses[3].data.body;
      });

      // for (let i in ) {
      //   lib.getDate(stats[i].date) < date && state.stats.push(stats[i]);
      // }
    };

    const setYears = () => {
      const currDate = new Date();
      const startDate = lib.getDate(projectType === "invest" ? props.project.fundingStartDate : props.project.investStartDate);
      const endDate = lib.getDate(projectType === "invest" ? props.project.fundingEndDate : props.project.investEndDate);

      for (let y = startDate.getFullYear(); y <= endDate.getFullYear(); y += 1) {
        y <= currDate.getFullYear() && state.years.push(y.toString());
      }
    };

    const setMonths = () => {
      const currDate = new Date();
      const startDate = lib.getDate(projectType === "invest" ? props.project.fundingStartDate : props.project.investStartDate);
      const endDate = lib.getDate(projectType === "invest" ? props.project.fundingEndDate : props.project.investEndDate);
      state.months = [];

      for (let i = 0; i < 12; i += 1) {
        const date = new Date(Number(state.args.year), i);
        date >= new Date(startDate.getFullYear(), startDate.getMonth())
        && date <= new Date(endDate.getFullYear(), endDate.getMonth())
        && date <= new Date(currDate.getFullYear(), currDate.getMonth())
        && state.months.push(lib.getNumberWithPadding(i + 1).toString());
      }

      if (state.months.length && !state.months.includes(state.args.month)) {
        state.args.month = state.months[0];
      }
    };

    const onChange = () => {
      setMonths();
      load();
    };

    return {component, state, onChange, load};
  }
});
</script>

<style lang="scss" scoped>
.manage-stats {
  padding-top: $px15;

  &.skeleton {
    > .row > div {
      .subject > span {
        @include skeleton;
      }

      table {
        th, td {
          span {
            @include skeleton;
          }
        }
      }
    }
  }
}
</style>